<template>
  <div class="main-container-changelog">
    <h2 class="mt-3 mb-3">Changelog Whappy</h2>
    <single-changelog
      v-for="item in changelog_list"
      :key="item.title"
      :data="item"
      class="mt-5"
    ></single-changelog>
  </div>
</template>

<script>
import SingleChangelog from "../components/SingleChangelog.vue";
import axios from "axios";
export default {
  name: "ChangeLog",
  components: {
    SingleChangelog,
  },
  created() {
    axios
      .get("https://api-demo.whappy.it/api/System/GetChangelog")
      .then((res) => {
        console.log("res changelog ", res);
        if (res) {
          this.changelog_list = res.data;
          return true;
        }
        return false;
      });
  },
  data() {
    return {
      changelog_list: [],
    };
  },
};
</script>