<template>
  <div class="single-changelog">
    <div class="single-changelog-sideline"></div>
    <h3 class="mb-2">{{ data.title }} ({{ data.date }})</h3>
    <div class="single-changelog-gencontent">
      <div
        v-for="item in data.content"
        :key="item.title"
        class="single-changelog-content"
      >
        <!-- <div class="single-changelog-content-icon">
          <b-icon
            :icon="item.icon"
            style="color: #0aace7"
            class="mr-3"
          ></b-icon>
        </div> -->
        <div class="single-changelog-content-text">
          <div class="single-changelog-content-text-title">
            {{ item.title
            }}<b-badge pill :variant="item.variant || 'dark'" class="ml-2"
              ><b-icon :icon="item.icon" class="mr-2"></b-icon
              >{{ item.category }}</b-badge
            >
          </div>
          <div class="single-changelog-content-text-content">
            <span v-html="item.description"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleChangelog",
  props: {
    data: Object,
  },
  mounted() {
    this.data.content.forEach((element) => {
      switch (element.category) {
        case "bug fixed":
          element.icon = "bug-fill";
          element.variant = "warning";
          break;

        case "new feature":
          element.icon = "star-fill";
          element.variant = "success";
          break;

        case "update feature":
          element.icon = "caret-up-square-fill";
          element.variant = "info";

          break;

        default:
          element.icon = "puzzle-fill";
          element.variant = "dark";

          break;
      }
    });
  },
};
</script>


